import React, { useCallback, useState } from 'react';
import {
  FaFile, FaMagic, FaTenge, FaThumbsDown,
  FaThumbsUp
} from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearch } from '../../services/searches';
import CdnFiles from '../ManageContent/CdnFiles';
import ResourceEmbeddingsList from '../lists/ResourceEmbeddingsList';
import { setSearchUserFeedback } from '../../actions/Search';

const SearchVectorResults = ({
  title = 'Context-Aware',
  vectorSearchId,
  vectorFiles,
  vectorNodes
}) => {
  const dispatch = useDispatch();
  // Redux
  const search = useSelector((state) => state.search);

  const [contextSearchDisplayType, setContextSearchDisplayType] = useState(
    localStorage.getItem('contextSearchDisplayType') || 'FILE'
  );

  const handleChangeContextSearchViewType = (csdType) => {
    setContextSearchDisplayType(csdType);
    localStorage.setItem('contextSearchDisplayType', csdType);
  };

  const renderRowSubComponent = useCallback(({ row }) => {
    return (
      <ResourceEmbeddingsList
        list={row?.original?.embeddings || []}
      />
    );
  }, []);

  return (
    <>
      <div className='card'>
        <div className="card-header bg-dark text-white d-flex align-items-center justify-content-between">
          <h5 className="m-0 d-flex align-items-center">
            <FaMagic
              title="Context-Aware Search Engine"
            />
            <span className='ml-2 d-flex align-items-center'>
              <span className='d-none d-sm-block mr-1'>
                {title}
              </span>
              <span>
                Results
              </span>
            </span>
          </h5>

          <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            <div className="btn-group" role="group" aria-label="Second group">
              <button
                type="button"
                className={`btn ${contextSearchDisplayType === 'FILE' ? 'btn-white pointer-events-none' : 'btn-outline-white'}`}
                aria-label='File'
                onClick={() => {
                  handleChangeContextSearchViewType('FILE');
                }}
                // data-tooltip-content="Files"
                // data-tooltip-id="SearchContentVector"
                data-tip="Files"
                data-for="SearchContentVector"
              >
                <FaFile />
              </button>
              <button
                type="button"
                className={`btn ${contextSearchDisplayType === 'TEXT' ? 'btn-white pointer-events-none' : 'btn-outline-white'}`}
                aria-label='Text'
                onClick={() => {
                  handleChangeContextSearchViewType('TEXT');
                }}
                // data-tooltip-content="Text"
                // data-tooltip-id="SearchContentVector"
                data-tip="Text"
                data-for="SearchContentVector"
              >
                <FaTenge />
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            boxShadow: '0px 0px 11px rgba(0, 0, 0, .2) inset'
          }}
        >
          {contextSearchDisplayType === 'FILE' && (
            <CdnFiles
              id="resource-integration-files"
              header={false}
              contentId={undefined}
              contentType="resourceGroup"
              // theme="light"
              files={vectorFiles}
              hiddenColumns={['createdAt', 'rawText', 'parentId']}
              sortByAccessorId="similarity"
              sortByAccessorDesc
              renderRowSubComponent={renderRowSubComponent}
            />
          )}
          {contextSearchDisplayType === 'TEXT' && (
            <div className='overflow-content'>
              <ResourceEmbeddingsList
                list={vectorNodes}
              />
            </div>
          )}
        </div>

        {vectorSearchId && (
          <div
            className='card-footer d-flex align-items-center justify-content-between'
          >
            <div className='text-muted small'>
              ID: {vectorSearchId}
            </div>
            <div className='d-flex align-items-center'>
              <div className='mr-3'>
                <div className='font-weight-bold'>Are these results helpful?</div>
                <div className='text-muted'>Your feedback helps us improve search results</div>
              </div>
              <button
                type="button"
                className={`mr-2 btn ${search.userFeedback === true ? 'btn-success' : 'btn-white'}`}
                onClick={() => {
                  const userFeedback = search.userFeedback === true ? null : true;

                  updateSearch({ userFeedback }, vectorSearchId).then(() => {
                    dispatch(setSearchUserFeedback({ userFeedback }));
                  });
                }}
                aria-label="Thumbs Up"
              >
                <FaThumbsUp />
              </button>
              <button
                type="button"
                className={`btn ${search.userFeedback === false ? 'btn-danger' : 'btn-white'}`}
                onClick={() => {
                  const userFeedback = search.userFeedback === false ? null : false;

                  updateSearch({ userFeedback }, vectorSearchId).then(() => {
                    dispatch(setSearchUserFeedback({ userFeedback }));
                  });
                }}
                aria-label="Thumbs Down"
              >
                <FaThumbsDown />
              </button>
            </div>
          </div>
        )}
      </div>

      <ReactTooltip id="SearchContentVector" place='bottom' />
    </>
  );
};

export default SearchVectorResults;
