import React from 'react';
import {
  FaExclamationTriangle, FaFile, FaFolder, FaFolderOpen
} from 'react-icons/fa';
import { useSelector } from 'react-redux';
import pluralize from 'pluralize';
import ReactTooltip from 'react-tooltip';
import { IMAGE_RESUME_SCREENER } from '../../../constants/assets';

const TitleScreen = ({
  resourceGroupTitle, hasFiles, hasEmbeddedFiles, embeddedFiles
}) => {
  // Redux
  const resourceGroup = useSelector((state) => state.resourceGroup);

  return (
    <>
      <div className='card-body overflow-y-scroll'>
        <div className='mx-auto py-3 px-0 px-lg-5 d-block d-sm-none'>
          <img
            className='w-100'
            src={IMAGE_RESUME_SCREENER}
            alt='Resume Screener'
          />
        </div>
        <div className='row'>
          <div className='col-12'>

            <div className='d-flex flex-column justify-content-center'>
              <div className='mx-auto py-3 px-0 px-lg-5 d-none d-sm-block'>
                <img
                  className='w-100'
                  src={IMAGE_RESUME_SCREENER}
                  alt='Resume Screener'
                />
              </div>
              <div className='d-flex flex-column justify-content-center'>
                <h5 className='text-center font-weight-bold my-3'>
                  Find top Candidates for an open role
                </h5>
                <p className='text-center mb-1'>
                  Analyze a Job Description and Resumes in:
                </p>
                <p className='text-center d-flex align-items-center justify-content-center'>
                  <span
                    className="h5 m-0 d-inline-flex align-items-center Resources-title-container p-2"
                  >
                    <FaFolderOpen size={20} className='d-none d-sm-block mr-2' />
                    <span className='Resources-title'>
                      {resourceGroupTitle}
                    </span>
                    {Array.isArray(resourceGroup?.list) && (
                      <span
                        className="h6 m-0 ml-2 d-none d-sm-block"
                        // data-tooltip-content={`${resourceGroup?.list.length} Folders`}
                        // data-tooltip-id="ResumeScreenerTitleScreen"
                        data-tip={pluralize('Folder', resourceGroup?.list.length, true)}
                        data-for="ResumeScreenerTitleScreen"
                      >
                        <span className="badge bg-white border d-flex align-items-center">
                          <FaFolder className='text-primary' />
                          <span className='ml-1'>
                            {resourceGroup?.list.length}
                          </span>
                        </span>
                      </span>
                    )}

                    {hasFiles && resourceGroup?.cdnFiles.length > 0 && resourceGroup?.cdnFiles.length !== embeddedFiles.length && (
                      <span
                        className="h6 m-0 ml-2 d-none d-sm-block"
                        // data-tooltip-content={`${resourceGroup?.cdnFiles.length} Files`}
                        // data-tooltip-id="ResumeScreenerTitleScreen"
                        data-tip={pluralize('File', resourceGroup?.cdnFiles.length, true)}
                        data-for="ResumeScreenerTitleScreen"
                      >
                        <span className="badge bg-white border d-flex align-items-center">
                          <FaFile size={10} />
                          <span className='ml-1'>
                            {resourceGroup?.cdnFiles.length}
                          </span>
                        </span>
                      </span>
                    )}

                    {hasEmbeddedFiles && (
                      <span
                        className="h6 m-0 ml-2 d-none d-sm-block"
                        // data-tooltip-content={`${resourceGroup?.cdnFiles.length} Files`}
                        // data-tooltip-id="ResumeScreenerTitleScreen"
                        data-tip={pluralize('Embedded File', embeddedFiles.length, true)}
                        data-for="ResumeScreenerTitleScreen"
                      >
                        <span className="badge bg-white border d-flex align-items-center">
                          <FaFile size={10} className='text-keppel' />
                          <span className='ml-1'>
                            {embeddedFiles.length}
                          </span>
                        </span>
                      </span>
                    )}
                  </span>
                </p>

                {!hasFiles && (
                  <p
                    className='font-weight-bold d-flex align-items-center justify-content-center'
                  >
                    <span className='alert-warning p-3 d-flex align-items-center rounded'>
                      <FaExclamationTriangle className='mr-2 text-warning' />
                      <span>
                        Add resumes (.docx or .pdf) to this folder first
                      </span>
                    </span>
                  </p>
                )}

                {!hasEmbeddedFiles && (
                  <p
                    className='font-weight-bold d-flex align-items-center justify-content-center'
                  >
                    <span className='alert-warning p-3 d-flex align-items-center rounded'>
                      <FaExclamationTriangle className='mr-2 text-warning' />
                      <span>
                        Embed resume files before running Resume Screener
                      </span>
                    </span>
                  </p>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
      <ReactTooltip id="ResumeScreenerTitleScreen" />
    </>
  );
};

export default TitleScreen;
