import React, { Fragment, useState } from 'react';
import ColorPicker from '../../ManageContent/ColorPicker';
import { CLASS_COLOR_DEFAULT } from '../../../constants/globals';

export default ({
  data, updateState, saveData
}) => {
  // Local State
  const [currentState, setState] = useState(data);
  const [loading, setLoading] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(false);

  const {
    title,
    classImage,
    classColor
  } = currentState;

  const handleSave = () => {
    setLoading(true);
    saveData();
  };

  const handleChange = (newState) => {
    const dataToSave = {
      ...currentState,
      ...newState
    };

    if (dataToSave.classColor) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }

    setState(dataToSave);
    updateState(dataToSave);
  };

  return (
    <Fragment>
      <div className="card-body">
        {classImage && (
          <div id="class-image" className="pb-4">
            <div className="font-weight-bold mb-2">
              Course Image:
            </div>
            <div className="border shadow-sm p-2 d-inline-block">
              <img
                src={classImage}
                alt={title}
                className="w-100"
                height="148"
                style={{
                  height: '148px'
                }}
              />
            </div>
          </div>
        )}

        <div id="course-color">
          <label htmlFor="classColor">
            <p className="m-0">
              Choose a course color:
            </p>
          </label>

          <ColorPicker
            label="Course Color"
            id="classColor"
            name="classColor"
            hexColor={classColor}
            hexColorDefault={CLASS_COLOR_DEFAULT}
            handleColorChange={handleChange}
            showLabel={false}
          />
        </div>
      </div>
      <div className="card-footer">
        <button
          className="btn btn-sm btn-primary"
          onClick={handleSave}
          type="button"
          disabled={nextDisabled || loading}
        >
          {loading ? 'Saving..' : 'Save'}
        </button>
      </div>
    </Fragment>
  );
};
