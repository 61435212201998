import React, { useState } from 'react';
import ColorSwatches from '../ColorSwatches';

const ColorPicker = ({
  id,
  name,
  label = '',
  hexColor,
  hexColorDefault,
  showLabel = true,
  handleColorChange
}) => {
  const [color, setColor] = useState(hexColor || hexColorDefault);

  const handleClick = (value) => {
    setColor(value);

    if (handleColorChange) {
      handleColorChange({ [name]: value });
    }
  };

  const handleResetColor = () => {
    if (handleColorChange) {
      handleColorChange({ [name]: undefined });
    }

    setColor('');
  };

  return (
    <>
      {showLabel && (
        <label htmlFor={id}>
          <b>{label}:</b> <span className="text-danger">*</span>
        </label>
      )}

      <ColorSwatches
        activeColor={color || hexColor}
        handleClick={handleClick}
        handleResetColor={handleResetColor}
      />

      <input
        id={id}
        type="hidden"
        className="form-control"
        name={name}
        defaultValue={color || hexColor}
      />
    </>
  );
};

export default ColorPicker;
