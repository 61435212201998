import React, { useState } from 'react';
import AgentImages from '../AgentImages';

const AgentImagePicker = ({
  id,
  name,
  label = '',
  url,
  urlDefault,
  showLabel = true,
  handleColorChange,
  bkgColor
}) => {
  const [imgSrc, setImgSrc] = useState(url || urlDefault);

  const handleClick = (value) => {
    setImgSrc(value);

    if (handleColorChange) {
      handleColorChange({ [name]: value });
    }
  };

  const handleResetImgSrc = () => {
    if (handleColorChange) {
      handleColorChange({ [name]: undefined });
    }

    setImgSrc('');
  };

  return (
    <>
      {showLabel && (
        <label htmlFor={id}>
          <b>{label}:</b> <span className="text-danger">*</span>
        </label>
      )}

      <AgentImages
        activeImgSrc={imgSrc || url}
        bkgColor={bkgColor}
        handleClick={handleClick}
        handleResetImgSrc={handleResetImgSrc}
      />

      <input
        id={id}
        type="hidden"
        className="form-control"
        name={name}
        defaultValue={imgSrc || url}
      />
    </>
  );
};

export default AgentImagePicker;
