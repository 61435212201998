import { pathOr } from 'ramda';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ICON_BRANDING } from '@apprentage/constants';
import { setCurrentClass } from '../../../actions/Class';
import { CLASS_COLOR_DEFAULT } from '../../../constants/globals';
import * as aws from '../../../services/aws';
import { updateCourse } from '../../../services/courses';
import ColorPicker from '../../ManageContent/ColorPicker';
import ImageUploader from '../../ManageContent/ImageUploader';

const Form = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const data = useSelector((state) => state.course);
  // Misc
  const orgId = pathOr(null, ['id'], organization);
  const classId = pathOr(null, ['id'], data);
  const [loading, setLoading] = useState(false);
  const [classImage, setClassImage] = useState(data.classImage || '');
  const [classImagePreview, setClassImagePreview] = useState(null);
  const [classColor, setClassColor] = useState(data.classColor || CLASS_COLOR_DEFAULT);
  const [classImageToDelete, setClassImageToDelete] = useState(null);
  const [isDirty, setIsDirty] = useState(false);

  const onImageUploadCallback = ({ url }) => {
    setIsDirty(true);
    setClassImage(url);
  };

  const previewFile = (file) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      // convert image file to base64 string
      setClassImagePreview(reader.result);
    }, false);

    if (file) {
      reader.readAsDataURL(file);
      setIsDirty(true);
    }
  };

  const removeClassImage = () => {
    setClassImageToDelete(classImage);
    setClassImage('');
    setIsDirty(true);
  };

  const resetForm = () => {
    setLoading(false);
    setIsDirty(false);
    // setClassImagePreview('');
    // setClassImageToDelete(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (classImageToDelete) {
      aws.deleteFile({
        url: classImageToDelete,
        orgId
      });
    }

    updateCourse({
      classId,
      classImage,
      classColor
    }).then(() => {
      toast.success('Branding settings updated!');
      dispatch(setCurrentClass({
        classId
      }));
      resetForm();
    });
  };

  return (
    <form
      name="form-course-branding"
      onSubmit={handleSubmit}
    >
      <div className="card border-bottom my-3">
        <div className="card-header p-3">
          <h1 className="h5 m-0">
            <i className={`${ICON_BRANDING} mr-1`} aria-hidden="true" /> Branding
          </h1>
        </div>
        <div className="card-body p-0">
          <div className="list-group list-group-flush">
            <div id="class-image" className="py-4 list-group-item">
              <div className="font-weight-bold mb-2">
                Course Image:
              </div>

              {(classImagePreview || classImage) && (
                <div className="border shadow-sm p-2" style={{ width: '150px' }}>
                  <img
                    src={classImagePreview || classImage}
                    alt="Course"
                    className="w-100"
                  />
                </div>
              )}

              <div className="d-flex align-items-start flex-column">
                <ImageUploader
                  inputId="classImage"
                  name="classImage"
                  style={{ margin: '.8rem 0' }}
                  hideInputField={classImage}
                  onImageUploadCallback={onImageUploadCallback}
                  onImagePreviewCallback={previewFile}
                />

                {classImage && (
                  <button
                    className="btn-link px-0"
                    onClick={removeClassImage}
                    type="button"
                  >
                    Remove Course Image
                  </button>
                )}
              </div>
            </div>

            <div id="course-color" className="list-group-item">
              <ColorPicker
                id="classColor"
                name="classColor"
                label="Course Color"
                hexColor={classColor}
                hexColorDefault={CLASS_COLOR_DEFAULT}
                handleColorChange={({ classColor: newClassColor }) => {
                  setIsDirty(!!newClassColor);
                  setClassColor(newClassColor);
                }}
              />
            </div>
          </div>

        </div>
        <div className="card-footer d-flex p-3">
          <button
            className="btn btn-primary d-block"
            type="submit"
            disabled={loading || !isDirty}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
