import pluralize from 'pluralize';
import React from 'react';
import { FaFile, FaFolder, FaFolderOpen } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

const Header = ({
  style = {},
  numCandidates,
  resourceGroupTitle,
  jobTitle,
  hasFiles,
  hasEmbeddedFiles,
  embeddedFiles
}) => {
  const resourceGroup = useSelector((state) => state.resourceGroup);

  return (
    <>
      <div
        className='mb-3'
        style={style}
      >
        <h5>
          Identify the top <strong>{numCandidates > 1 && numCandidates} Candidate{numCandidates > 1 && 's'}</strong>{jobTitle ? ' for ' : ' '}{jobTitle ? <strong>{jobTitle}</strong> : ' '} from <strong>Resumes</strong> in:
        </h5>

        <div className='shadow p-3 py-4 border'>
          <div className='d-flex justify-content-center'>
            <span
              className="h5 m-0 d-inline-flex align-items-center Resources-title-container p-2"
            >
              <FaFolderOpen size={20} className='d-none d-sm-block mr-2' />
              <span className='Resources-title'>
                {resourceGroupTitle}
              </span>
              {Array.isArray(resourceGroup?.list) && (
                <span
                  className="h6 m-0 ml-2 d-none d-sm-block"
                  // data-tooltip-content={`${resourceGroup?.list.length} Folders`}
                  // data-tooltip-id="ResumeScreenerHeader"
                  data-tip={pluralize('Folder', resourceGroup?.list.length, true)}
                  data-for="ResumeScreenerHeader"
                >
                  <span className="badge bg-white border d-flex align-items-center">
                    <FaFolder className='text-primary' />
                    <span className='ml-1'>
                      {resourceGroup?.list.length}
                    </span>
                  </span>
                </span>
              )}

              {hasFiles && resourceGroup?.cdnFiles.length > 0 && resourceGroup?.cdnFiles.length !== embeddedFiles.length && (
                <span
                  className="h6 m-0 ml-2 d-none d-sm-block"
                  // data-tooltip-content={`${resourceGroup?.cdnFiles.length} Files`}
                  // data-tooltip-id="ResumeScreenerHeader"
                  data-tip={pluralize('File', resourceGroup?.cdnFiles.length, true)}
                  data-for="ResumeScreenerHeader"
                >
                  <span className="badge bg-white border d-flex align-items-center">
                    <FaFile size={10} />
                    <span className='ml-1'>
                      {resourceGroup?.cdnFiles.length}
                    </span>
                  </span>
                </span>
              )}

              {hasEmbeddedFiles && (
                <span
                  className="h6 m-0 ml-2 d-none d-sm-block"
                  // data-tooltip-content={`${resourceGroup?.cdnFiles.length} Files`}
                  // data-tooltip-id="ResumeScreenerHeader"
                  data-tip={pluralize('Embedded File', embeddedFiles.length, true)}
                  data-for="ResumeScreenerHeader"
                >
                  <span className="badge bg-white border d-flex align-items-center">
                    <FaFile size={10} className='text-keppel' />
                    <span className='ml-1'>
                      {embeddedFiles.length}
                    </span>
                  </span>
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
      <ReactTooltip id="ResumeScreenerHeader" />
    </>
  );
};

export default Header;
