import React, { useState } from 'react';
import AgentImage from './AgentImage';
import './style.css';

const imgSrcHash = {
  'https://turbine-content.s3.us-east-2.amazonaws.com/bot-helmet.png': 'Robot',
  'https://turbine-content.s3.us-east-2.amazonaws.com/bot-helmet-blue.png': 'Robot Blue',
  'https://turbine-content.s3.us-east-2.amazonaws.com/bot-helmet-black.png': 'Robot Black',
  'https://turbine-content.s3.us-east-2.amazonaws.com/bot-helmet-white.png': 'Robot White'
};

const agentImages = Object.entries(imgSrcHash).map(([key, value]) => ({
  name: value,
  imgSrc: key
}));

const AgentImages = ({
  handleClick,
  handleResetImgSrc,
  activeImgSrc = '',
  bkgColor
}) => {
  const isCustomImgSrc = imgSrcHash[activeImgSrc] === undefined;

  const [imgSrc, setImgSrc] = useState(activeImgSrc);
  const [customImgSrc, setCustomImgSrc] = useState(isCustomImgSrc ? activeImgSrc : '');

  const onClick = (value) => {
    if (handleClick) {
      handleClick(value);
    }
    setCustomImgSrc('');
    setImgSrc(value);
  };

  const onChange = (e) => {
    const { value } = e.currentTarget;

    setCustomImgSrc((value || ''));

    if (value && value.includes('https://')) {
      if (handleClick) {
        handleClick(value);
      }

      setImgSrc(value);
    } else {
      if (handleResetImgSrc) {
        handleResetImgSrc();
      }

      setImgSrc('');
    }
  };

  return (
    <>
      <div className="AgentImages">
        {agentImages.map((c, i) => (
          <AgentImage
            key={i}
            imgSrc={c.imgSrc}
            imgName={c.name}
            handleClick={onClick}
            activeImgSrc={isCustomImgSrc ? undefined : imgSrc}
            bkgColor={bkgColor}
          />
        ))}

        {customImgSrc && isCustomImgSrc && (
          <AgentImage
            imgSrc={customImgSrc}
            imgName="Custom Image"
            activeImgSrc={customImgSrc}
            bkgColor={bkgColor}
          />
        )}
      </div>

      <div className='mt-2'>
        <div className='d-flex align-items-center'>
          <span className='mr-1 font-weight-bold'>Custom Image</span>
        </div>
        <div className='mb-2'>
          Image Dimensions: 200 x 200 (pixels)
        </div>
        <input
          placeholder=''
          className='form-control'
          onChange={onChange}
          value={customImgSrc}
        />
        <div className='small text-muted mt-1'>
          Ex: https://website.com/image.png
        </div>
      </div>
    </>
  );
};

export default AgentImages;
