import React, { useCallback, useState } from 'react';
import Color from './Color';
import './style.css';

const colorsHash = {
  '#b3bac5': 'grey',
  '#344563': 'black',
  '#61bd4f': 'green',
  '#f2d600': 'yellow',
  '#ff9f1a': 'orange',
  '#eb5a46': 'red',
  '#c377e0': 'purple',
  '#0079bf': 'blue',
  '#00c2e0': 'sky',
  '#51e898': 'lime',
  '#ff78cb': 'pink'
};

const colors = Object.entries(colorsHash).map(([key, value]) => ({
  name: value,
  hex: key
}));

const ColorSwatches = ({
  handleClick,
  handleResetColor,
  activeColor = ''
}) => {
  const isCustomHex = colorsHash[activeColor.toLowerCase()] === undefined;

  const [color, setColor] = useState(activeColor);
  const [customHex, setCustomHex] = useState(isCustomHex ? activeColor : '');

  const onClick = (value) => {
    if (handleClick) {
      handleClick(value);
    }
    setCustomHex('');
    setColor(value);
  };

  const isValidHex = useCallback((value) => {
    return value.length === 7 && value.includes('#');
  }, []);

  const onChange = (e) => {
    const { value } = e.currentTarget;

    setCustomHex((value || '').toLowerCase());

    if (isValidHex(value)) {
      if (handleClick) {
        handleClick((value || '').toLowerCase());
      }

      setColor((value || '').toLowerCase());
    } else {
      if (handleResetColor) {
        handleResetColor();
      }

      setColor('');
    }
  };

  return (
    <>
      <div className="ColorSwatches">
        {colors.map((c, i) => (
          <Color
            key={i}
            color={c.hex}
            handleClick={onClick}
            activeColor={isCustomHex ? undefined : color}
          />
        ))}
      </div>

      <div className='mt-2'>
        <div className='d-flex align-items-center mb-1'>
          <span className='mr-1 font-weight-bold'>Custom Color</span>
          <span className='badge badge-secondary'>
            HEX6
          </span>
        </div>
        <div className='d-flex align-items-start'>
          <div>
            <input
              placeholder=''
              className='form-control'
              style={{
                maxWidth: '128px'
              }}
              onChange={onChange}
              value={customHex}
              max={7}
            />
            <div className='small text-muted mt-1'>
              Ex: #FF2200
            </div>
          </div>

          {customHex && isCustomHex && isValidHex(customHex) && (
            <Color
              key="custom-color"
              className="ml-2"
              color={customHex}
              activeColor={customHex}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ColorSwatches;
