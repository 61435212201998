import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  FaDatabase, FaEye, FaImage, FaInfoCircle, FaPaintBrush, FaPlus, FaTenge
} from 'react-icons/fa';
import { ORG_CONSOLE_ASSISTANT_BASE, ORG_CONSOLE_ASSISTANTS } from '../../constants/routes';
import { setCurrentModal } from '../../actions/Modals';
import { MODAL_KEY_MANAGE_PROMPT } from '../../constants/modals';
import { createIntegration, updateIntegration } from '../../services/integrations';
import { SHEET_KEY_CHOOSE_RESOURCE_FOLDER } from '../../constants/sideSheets';
import { resetSideSheet, setSideSheet } from '../../actions/SideSheets';
import removeStringFromArray from '../../utils/removeStringFromArray';
import DeleteAssistant from '../../components/ManageContent/DeleteContent/DeleteAssistant';
import ItemsDnd from '../../components/ManageContent/Dnd/Items';
import ColorPicker from '../../components/ManageContent/ColorPicker';
import AgentImagePicker from './AgentImagePicker';
import SearchFolders from '../../components/SearchContent/SearchFolders';
import AgentImage from './AgentImages/AgentImage';
import TagInput from '../../components/ManageContent/TagInput';
import Switch from '../../components/Switch';
import MessageContainer from '../Assistant/MessageContainer';
import DefaultPrompts from '../Assistant/DefaultPrompts';

const Form = () => {
  const orgNicknameRef = useRef(null);
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const assistant = useSelector((state) => state.assistant);
  // Organization
  const orgId = organization?.id || '';
  const orgName = organization?.name || '';
  // Current User
  const userId = currentUser?.id || null;
  // Default Values
  const defaultColorBtnBkg = '#000000';
  const defaultWelcomeMessage = "👋🏻 Hello, I'm an AI Assistant here to answer any questions you have about {ORG_NICKNAME}.";
  const defaultAgentImage = 'https://turbine-content.s3.us-east-2.amazonaws.com/bot-helmet.png';
  // Local State
  const [loading, setLoading] = useState(false);
  const [useOrgName, setUseOrgName] = useState(true);
  // Local State DB vals
  const [name, setName] = useState(assistant?.name || 'My New Assistant');
  const [colorBtnBkg, setColorBtnBkg] = useState(assistant?.colorBtnBkg || defaultColorBtnBkg);
  const [welcomeMessage, setWelcomeMessage] = useState(assistant?.welcomeMessage || defaultWelcomeMessage);
  const [orgNickname, setOrgNickname] = useState(assistant?.orgNickname || '');
  const [embedUrls, setEmbedUrls] = useState(assistant?.embedUrls || []);
  const [resourceGroupIds, setResourceGroupIds] = useState(assistant?.resourceGroupIds || null);
  const [agentImage, setAgentImage] = useState(assistant?.agentImage || defaultAgentImage);
  const [archived, setArchived] = useState(assistant?.archived || false);
  const [defaultPrompts, setDefaultPrompts] = useState(assistant?.defaultPrompts || []);

  const showSuccess = () => {
    toast.success('Project saved!');
    setLoading(false);
  };

  const managePrompt = (data) => {
    console.log(data);
    const config = {
      key: MODAL_KEY_MANAGE_PROMPT,
      data: {
        index: data?.index || 0
      },
      callbackPrimaryAction: (prompt) => {
        const newDefaultPrompts = [...defaultPrompts || []];

        newDefaultPrompts[prompt.index] = prompt.text;

        setDefaultPrompts(newDefaultPrompts);
      }
    };

    if (data?.item?.title) {
      config.data.text = data?.item?.title;
    }

    dispatch(setCurrentModal(config));
  };

  const chooseKnowledgeBase = () => {
    dispatch(setSideSheet({
      key: SHEET_KEY_CHOOSE_RESOURCE_FOLDER,
      data: {
        title: 'Choose Knowledge Base',
        moveToResources: true
      },
      callbackPrimaryAction: (resourceGroupId) => {
        const newResourceGroupIds = [...(resourceGroupIds || [])];

        if (resourceGroupId) {
          newResourceGroupIds.push(resourceGroupId);
          setResourceGroupIds(newResourceGroupIds);
        }

        dispatch(resetSideSheet());
      }
    }));
  };

  /**
   *
   * @param {object} param.item
   * @param {number} param.index item's index in array
   */
  const handleDeleteItem = ({ item }) => {
    let newDefaultPrompts = [...defaultPrompts];

    newDefaultPrompts = removeStringFromArray(defaultPrompts, item?.title);

    setDefaultPrompts(newDefaultPrompts);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dataToSave = {
      name,
      version: 1,
      type: 'embedRagResourceGroups',
      colorBtnBkg: colorBtnBkg || null,
      welcomeMessage: welcomeMessage || null,
      orgNickname: orgNickname || null,
      embedUrls: null,
      resourceGroupIds: null,
      agentImage: agentImage || null,
      archived: archived || null,
      defaultPrompts: null
    };

    if (Array.isArray(defaultPrompts) && defaultPrompts.length > 0) {
      dataToSave.defaultPrompts = defaultPrompts;
    }

    if (Array.isArray(resourceGroupIds) && resourceGroupIds.length > 0) {
      dataToSave.resourceGroupIds = resourceGroupIds;
    }

    if (Array.isArray(embedUrls) && embedUrls.length > 0) {
      dataToSave.embedUrls = embedUrls;
    }

    if (!dataToSave.colorBtnBkg) {
      toast.error('Button Color is required.');
      return;
    }

    if (!dataToSave.agentImage) {
      toast.error('Button Image is required.');
      return;
    }

    if (!dataToSave.resourceGroupIds) {
      toast.error('Knowledge Base is required');
      return;
    }

    if (!dataToSave.embedUrls) {
      toast.error('Enter at least one Web Page URL');
      return;
    }

    setLoading(true);

    if (assistant?.id) {
      updateIntegration(dataToSave, assistant?.id).then(() => {
        showSuccess();
        document.location = `${ORG_CONSOLE_ASSISTANT_BASE}/${assistant.id}`;
      });
    } else {
      createIntegration({
        ...dataToSave,
        orgId,
        userId
      }).then((response) => {
        if (response?.id) {
          showSuccess();
          document.location = `${ORG_CONSOLE_ASSISTANT_BASE}/${response?.id}`;
        } else if (response?.error) {
          console.error(response?.message || response?.error);
          toast.error(response.error);
        }
      });
    }
  };

  const onChangeResourceGroupIds = (groupId) => {
    let newSearchGroupIds = [];

    if (Array.isArray(resourceGroupIds)) {
      newSearchGroupIds = resourceGroupIds.filter((gId) => gId !== groupId);
    }

    if (Array.isArray(newSearchGroupIds) && newSearchGroupIds.length > 0) {
      setResourceGroupIds(newSearchGroupIds);
    } else {
      setResourceGroupIds(null);
    }
  };

  const initialPrompts = useMemo(() => {
    if (!defaultPrompts || (Array.isArray(defaultPrompts) && defaultPrompts.length === 0)) {
      return null;
    }

    return defaultPrompts.map((prompt) => ({
      postAsCpu: true,
      isClickable: true,
      onlyMessage: true,
      message: prompt
    }));
  }, [defaultPrompts]);

  const handleColorChange = ({ colorBtnBkg: newColorBtnBkg }) => {
    setColorBtnBkg(newColorBtnBkg);
  };

  const handleAgentImageChange = ({ agentImage: newAgentImage }) => {
    setAgentImage(newAgentImage);
  };

  useEffect(() => {
    if (!useOrgName) {
      orgNicknameRef.current.focus();
    }
  }, [useOrgName]);

  return (
    <>
      <div
        className="d-flex align-items-center shadow mt-3 rounded rounded-3 bg-white p-3"
      >
        <AgentImage
          className="mb-0"
          imgSrc={agentImage}
          imgName={name}
          bkgColor={colorBtnBkg}
        />
        <h4 className="m-0">{name}</h4>
      </div>

      <form onSubmit={handleSubmit}>
        <div className='row my-4'>
          <div className='col-6'>
            <div className="card h-100">
              <div
                className="card-header d-flex align-items-center justify-content-between"
              >
                <div className='d-flex align-items-center h6 m-0 font-weight-bold'>
                  <FaInfoCircle />
                  <span className='ml-2'>
                    Configuration
                  </span>
                </div>
                {assistant?.id && (
                  <Switch
                    id="archived"
                    label="Archived"
                    value={archived}
                    onChange={() => {
                      setArchived(!archived);
                    }}
                  />
                )}
              </div>
              <div className='card-body p-0 h-100'>
                <div className="p-3 border-bottom">
                  <div className="mb-2">
                    <strong>Name:</strong> <span className="text-danger">*</span>
                  </div>
                  <div className="mb-2">
                    Internal name for your reference.
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={name}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setName(value);
                    }}
                    autoFocus={!assistant?.id}
                    required
                  />
                  <div className="text-muted small mt-1">
                    Ex: Marketing Website FAQ
                  </div>
                </div>

                <div className="p-3 border-bottom">
                  <div className="mb-2 d-flex align-items-center justify-content-between">
                    <div>
                      <strong>Organization Nickname:</strong> {!useOrgName && (<span className="text-danger">*</span>)}
                    </div>
                    <Switch
                      id="useOrgName"
                      label="Organization Name"
                      value={useOrgName}
                      onChange={() => {
                        setUseOrgName(!useOrgName);
                        setOrgNickname('');
                      }}
                    />
                  </div>

                  {useOrgName ? (
                    <input
                      type="text"
                      className="form-control"
                      name="orgName"
                      value={orgName}
                      readOnly
                      disabled
                    />
                  ) : (
                    <input
                      id="testing"
                      ref={orgNicknameRef}
                      type="text"
                      className="form-control"
                      name="orgNickname"
                      value={orgNickname}
                      required={!useOrgName}
                      onChange={(e) => {
                        const { value } = e.currentTarget;

                        setOrgNickname(value);
                      }}
                    />
                  )}

                </div>

                <div className="p-3 border-bottom">
                  <div className="mb-2">
                    <b>Welcome Message:</b> <span className="text-danger">*</span>
                  </div>
                  <textarea
                    className="form-control"
                    name="welcomeMessage"
                    value={welcomeMessage}
                    required
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setWelcomeMessage(value);
                    }}
                  />
                  <div className="text-muted small">
                    Use {'{ORG_NICKNAME}'} = {assistant?.orgNickname || orgName}
                  </div>
                </div>

                <div className="p-3">
                  <div className="mb-1">
                    <b>Website URLs:</b> <span className="text-danger">*</span>
                  </div>
                  <div className='mb-2'>
                    Enter the URLs where you will embed this Assistant.
                  </div>
                  <TagInput
                    id="embedUrls"
                    name="embedUrls"
                    tags={embedUrls}
                    onChange={({ embedUrls: newEmbedUrls }) => {
                      // null converted to undefined when data is formatted before sent to BE
                      setEmbedUrls(
                        newEmbedUrls.length === 0 ? null : newEmbedUrls
                      );
                    }}
                  />
                  <div className='small text-muted mt-1'>
                    Ex: https://website.com, https://blog.website.com
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className="card h-100">
              <div
                className="card-header d-flex align-items-center justify-content-between"
                style={{
                  height: '59px'
                }}
              >
                <div className='d-flex align-items-center h6 m-0 font-weight-bold'>
                  <FaEye />
                  <span className='ml-2'>
                    Preview
                  </span>
                </div>

              </div>
              <div className='card-body bkg-pattern-light h-100'>
                <MessageContainer
                  agentImage={agentImage}
                  colorBtnBkg={colorBtnBkg}
                  postAsCpu
                  orgName={orgNickname || orgName}
                  text={welcomeMessage.replace('{ORG_NICKNAME}', orgNickname || orgName)}
                  id="welcome-message"
                />

                {Array.isArray(defaultPrompts) && defaultPrompts.length > 0 && (
                  <MessageContainer
                    onlyMessage
                  >
                    <>
                      <DefaultPrompts
                        list={initialPrompts}
                      />
                    </>
                  </MessageContainer>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3 mb-4">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold">
              <span className='d-flex align-items-center'>
                <FaPaintBrush />
                <span className='ml-2'>
                  Color
                </span>
              </span>
            </h6>
          </div>
          <div className='card-body'>
            <ColorPicker
              label="Button Background Color"
              id="colorBtnBkg"
              name="colorBtnBkg"
              hexColor={colorBtnBkg}
              hexColorDefault={colorBtnBkg}
              handleColorChange={handleColorChange}
              showLabel={false}
            />
          </div>
        </div>

        <div className="card mt-3 mb-4">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold">
              <span className='d-flex align-items-center'>
                <FaImage />
                <span className='ml-2'>
                  Image
                </span>
              </span>
            </h6>
          </div>
          <div className='card-body'>
            <AgentImagePicker
              label="Agent Image"
              id="agentImage"
              name="agentImage"
              url={agentImage}
              urlDefault={defaultAgentImage}
              handleColorChange={handleAgentImageChange}
              showLabel={false}
              bkgColor={colorBtnBkg}
            />
          </div>
        </div>

        <div className="card mt-3 mb-4">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold">
              <span className='d-flex align-items-center'>
                <FaDatabase />
                <span className='ml-2'>
                  Knowledge Base
                </span>
              </span>
            </h6>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary d-flex align-items-center"
              onClick={chooseKnowledgeBase}
            >
              <FaPlus size={18} />
              <span className="ml-1">Add</span>
            </button>
          </div>
          <div className='card-body'>
            {Array.isArray(resourceGroupIds) && resourceGroupIds.length > 0 ? (
              <SearchFolders
                ids={resourceGroupIds}
                onChange={onChangeResourceGroupIds}
              />
            ) : (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{
                  height: '50px'
                }}
              >
                <span>
                  No Knowledge Base
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="card mt-3 mb-4">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h6 className="m-0 font-weight-bold">
              <span className='d-flex align-items-center'>
                <FaTenge />
                <span className='ml-2'>
                  Default Prompts
                </span>
              </span>
            </h6>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary d-flex align-items-center"
              onClick={() => {
                managePrompt({ index: Array.isArray(defaultPrompts) ? defaultPrompts.length : 0 });
              }}
            >
              <FaPlus size={18} />
              <span className="ml-1">New</span>
            </button>
          </div>
          <div className='card-body p-0'>
            {Array.isArray(defaultPrompts) && defaultPrompts.length > 0 ? (
              <ItemsDnd
                key={defaultPrompts.length}
                items={defaultPrompts.map((prompt, i) => ({ id: `prompt-${i}`, title: prompt }))}
                handleDeleteItem={handleDeleteItem}
                handleEditItem={managePrompt}
                onUpdate={(newListOrder) => {
                  const newDefaultPrompts = newListOrder.map((item) => item?.title);
                  setDefaultPrompts(newDefaultPrompts);
                }}
              />

            ) : (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{
                  height: '50px'
                }}
              >
                <span>
                  No default prompts
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="bg-white border rounded p-3 d-flex justify-content-between">
          <div>
            <button
              className="btn btn-primary mr-2"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>

            <Link
              type="button"
              name="Cancel"
              className="btn btn-sm btn-link"
              disabled={loading}
              to={assistant?.id ? `${ORG_CONSOLE_ASSISTANT_BASE}/${assistant?.id}` : ORG_CONSOLE_ASSISTANTS}
            >
              Cancel
            </Link>
          </div>
        </div>

        {assistant?.id && (
          <DeleteAssistant
            name={name}
            className="mt-2"
            contentId={assistant?.id}
          />
        )}
      </form>
    </>
  );
};

export default Form;
