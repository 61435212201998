import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import prettyBytes from 'pretty-bytes';
import {
  FaEye,
  FaCloudDownloadAlt,
  FaCog,
  FaRegTrashAlt,
  FaEllipsisH,
  FaExternalLinkAlt,
  FaMagic,
  FaCheckCircle,
  FaTimes,
  FaSpinner,
  FaLink,
  FaFolderMinus
} from 'react-icons/fa';
import { MdDriveFileMove } from 'react-icons/md';
import { useFlags } from 'flagsmith/react';
import {
  FLAG_RESOURCES_READONLY,
  NOTICE_RESOURCES_READONLY_TITLE
} from '../../../../constants/flagsmith';
import { showFeatureFlagNoticeModal } from '../../../../actions/FeatureFlags';
import { resetCurrentModal, setCurrentModal } from '../../../../actions/Modals';
import { downloadResource, fetchResource } from '../../../../services/resources';
import copyToClipBoard from '../../../../utils/copyToClipBoard';
import canUnpublishMaterial from '../../../../permissions/manage/canUnpublishMaterial';
import { embedDoc, vectorSearchDocTypes } from '../../../../services/openAi';
import { MODAL_KEY_CONFIRM_ACTION, MODAL_KEY_MANAGE_CDN_FILE, MODAL_KEY_RESOURCE_PREVIEW } from '../../../../constants/modals';
import { getResourcesByParentId } from '../../../../actions/Resources';
import { getResourceMaterial } from '../../../../actions/Materials';
import { setSideSheet } from '../../../../actions/SideSheets';
import { SHEET_KEY_MATERIAL, SHEET_KEY_MATERIAL_ADD } from '../../../../constants/sideSheets';
import { unpublishMaterial } from '../../../../services/materials';
import { TURBINE_ADMIN } from '../../../../constants/urls';
import { ORG_CONSOLE_RESOURCES } from '../../../../constants/routes';
import { canDeleteResource, canEditResource } from '../../../../services/currentUser';
import { canReEmbedResources } from '../../../../permissions/manage';

const EditCell = ({
  contentId,
  contentType,
  value: fileId,
  row,
  onClickDelete,
  editMenu
}) => {
  const dispatch = useDispatch();
  const flags = useFlags([FLAG_RESOURCES_READONLY]);
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const resourceGroup = useSelector((state) => state.resourceGroup);
  // Organization
  const orgId = organization?.id || '';
  // Resource / File
  const size = row?.original?.size || '';
  const url = row?.original?.url || '';
  const name = row?.original?.name || '';
  const type = row?.original?.type || '';
  const isEmbedded = row?.original?.isEmbedded || null;
  const resourceUserId = row?.original?.userId || null;
  const resourceContentId = row?.original?.contentId || null;
  // Current User
  const userId = currentUser?.id || null;
  const role = useMemo(() => {
    return currentUser?.role || [];
  }, [currentUser?.role]);
  // Local State
  const [loading, setLoading] = useState(false);
  const [embedding, setEmbedding] = useState(false);
  const [fileEmbedded, setFileEmbedded] = useState(isEmbedded);

  const onError = (error) => {
    setEmbedding(false);
    console.error(error?.message);
    toast.error('Something went wrong, try again.', { autoClose: false });
  };

  const handleCreateEmbedding = async () => {
    setEmbedding(true);
    toast.info(`Embedding ${name}...`, { autoClose: false, toastId: 'docEmbedInfo' });

    try {
      const response = await embedDoc({
        refTable: 'resources',
        orgId,
        id: fileId,
        url
      });

      if (response?.error) {
        onError(response?.error);
        return;
      }

      toast.dismiss('docEmbedInfo');
      toast.success(`${name} embedded!`);
      setEmbedding(false);
      setFileEmbedded(true);
      if (resourceGroup?.id) {
        dispatch(getResourcesByParentId(resourceGroup?.id));
      }
    } catch (error) {
      onError(error);
    }
  };

  const onClickDownload = () => {
    downloadResource({ name, file: url, orgId }).then((downloadUrl) => {
      if (downloadUrl) {
        window.location.assign(downloadUrl);
      }
    });
  };

  const onClickView = () => {
    if (type === 'material') {
      dispatch(getResourceMaterial({ resourceId: fileId, orgId })).then(() => {
        dispatch(setSideSheet({
          key: SHEET_KEY_MATERIAL,
          className: 'MaterialSideSheet'
        }));
      });
    } else {
      dispatch(setCurrentModal({
        key: MODAL_KEY_RESOURCE_PREVIEW,
        data: {
          resource: row?.original,
          modalTitle: name,
          iframeSrc: url
        }
      }));
    }
  };

  const handleFileSettingsClick = () => {
    if (flags?.resources_readonly?.enabled && flags?.resources_readonly?.value) {
      dispatch(showFeatureFlagNoticeModal({
        modalTitle: NOTICE_RESOURCES_READONLY_TITLE
      }));
      return;
    }

    if (type === 'material') {
      dispatch(getResourceMaterial({ resourceId: fileId, orgId })).then(() => {
        dispatch(setSideSheet({
          key: SHEET_KEY_MATERIAL,
          data: {
            editMode: true
          },
          className: 'MaterialSideSheet'
        }));
      });
    } else {
      dispatch(setCurrentModal({
        key: MODAL_KEY_MANAGE_CDN_FILE,
        data: {
          modalTitle: `${row?.original?.type === 'link' ? 'Link' : 'File'} Settings`,
          currentFile: row?.original,
          currentFileId: fileId,
          contentId,
          contentType
        }
      }));
    }
  };

  const checkEmbeddingStatus = useCallback(async () => {
    setLoading(true);
    const response = await fetchResource(fileId);

    setFileEmbedded(response?.isEmbedded);

    setLoading(false);
  }, [fileId]);

  const resourceLinkToCopy = useMemo(() => {
    const baseUrl = TURBINE_ADMIN + ORG_CONSOLE_RESOURCES;
    const urlParams = new URLSearchParams({
      fileId
    });

    return `${baseUrl}?${urlParams.toString()}`;
  }, [fileId]);

  const canEdit = useMemo(() => {
    if (editMenu && canEditResource({ role, userId, resourceUserId })) {
      return true;
    }

    return false;
  }, [editMenu, resourceUserId, role, userId]);

  const canDelete = useMemo(() => {
    if (editMenu && canDeleteResource({ role, userId, resourceUserId })) {
      return true;
    }

    return false;
  }, [editMenu, resourceUserId, role, userId]);

  // if (!contentId || !contentType) {
  //   return null;
  // }

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          data-cy={`cdnFiles-editMenu-${fileId}`}
          className="btn btn-link btn-sm rounded text-ships-officer"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage Menu"
          aria-label="Manage Menu"
          onClick={() => {
            if (type && vectorSearchDocTypes.includes(type)) {
              checkEmbeddingStatus();
            }
          }}
        >
          <FaEllipsisH size={15} />
        </button>
        <div
          className="dropdown-menu dropdown-menu-right p-0"
        >
          {type === 'link' && (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="dropdown-item py-2 px-3 d-flex align-items-center border-bottom"
              title="View in new tab"
              aria-label="View in new tab"
            >
              <FaExternalLinkAlt size={13} />
              <span className='ml-2'>
                Visit Link
              </span>
            </a>
          )}

          {type !== 'link' && (
            <button
              type="button"
              className="dropdown-item py-2 px-3 d-flex align-items-center border-bottom"
              onClick={onClickView}
              title="View"
              aria-label="View"
            >
              <FaEye size={15} />
              <span className='ml-2'>
                View
              </span>
            </button>
          )}

          {type !== 'link' && type !== 'material' && (
            <button
              type="button"
              className="dropdown-item py-2 px-3 d-flex align-items-center border-bottom"
              onClick={onClickDownload}
            >
              <FaCloudDownloadAlt size={15} />
              <span className='ml-2'>
                Download
              </span>
            </button>
          )}

          {type !== 'link' && (
            <button
              type="button"
              title='Copy link'
              className="dropdown-item border-bottom py-2 px-3 d-flex align-items-center"
              onClick={() => {
                copyToClipBoard(resourceLinkToCopy);
                toast.success('Link Copied!');
              }}
            >
              <FaLink size={15} />
              <span className='ml-2'>
                Copy link
              </span>
            </button>
          )}

          {type === 'material' && (
            <button
              className="dropdown-item border-bottom py-2 px-3"
              type='button'
              onClick={() => {
                dispatch(getResourceMaterial({ resourceId: fileId, orgId })).then((material) => {
                  dispatch(setSideSheet({
                    key: SHEET_KEY_MATERIAL_ADD,
                    data: {
                      title: 'Move',
                      material,
                      moveToResources: true
                    }
                  }));
                });
              }}
            >
              <span className='d-flex align-items-center'>
                <MdDriveFileMove size={20} />
                <span className='ml-2'>
                  Move
                </span>
              </span>
            </button>
          )}

          {type === 'material' && canUnpublishMaterial({ role, userId, resourceUserId }) && (
            <button
              className="dropdown-item border-bottom py-2 px-3"
              type='button'
              onClick={() => {
                dispatch(setCurrentModal({
                  key: MODAL_KEY_CONFIRM_ACTION,
                  data: {
                    title: 'Unpublish resource?',
                    subtitle: 'This resource will only be accessible to you.',
                    primaryActionBtnText: 'Yes, unpublish'
                  },
                  callbackPrimaryAction: () => {
                    unpublishMaterial({
                      id: resourceContentId,
                      resourceId: fileId
                    }).then(() => {
                      if (resourceGroup?.id) {
                        // If in Resources, refresh list to show updates
                        dispatch(getResourcesByParentId(resourceGroup?.id, 'resourceGroup'));
                      }
                      dispatch(resetCurrentModal());
                    });
                  }
                }));
              }}
            >
              <span className='d-flex align-items-center'>
                <FaFolderMinus size={18} />
                <span className='ml-2 small'>
                  Unpublish
                </span>
              </span>
            </button>
          )}

          {(contentId && contentType) && (
            <>
              {canEdit && editMenu && (
                <button
                  className="dropdown-item border-bottom py-2 px-3 d-flex align-items-center"
                  type="button"
                  title="File Settings"
                  onClick={handleFileSettingsClick}
                >
                  <FaCog size={15} />
                  <span className='ml-2'>
                    Settings
                  </span>
                </button>
              )}

              {canDelete && type !== 'material' && (
                <button
                  data-cy={`cdnFiles-deleteBtn-${fileId}`}
                  type="button"
                  className="dropdown-item py-2 px-3 d-flex align-items-center"
                  onClick={() => {
                    onClickDelete(row?.original);
                  }}
                >
                  <FaRegTrashAlt
                    className='text-danger mr-2'
                    size={15}
                  />
                  <span>
                    Delete
                  </span>
                </button>
              )}
            </>
          )}

          {type && vectorSearchDocTypes.includes(type) && (
            <div
              className='border-top bg-light px-2 py-1 d-flex align-items-center justify-content-end small'
            >
              <span className='d-flex align-items-center'>
                <FaMagic className='opacity-25' />
                <span
                  className='ml-2 text-nowrap'
                  title="Context-Aware Search Engine"
                >
                  Context-Aware Search
                </span>
              </span>

              <span className='d-flex align-items-center ml-2'>

                {fileEmbedded ? (
                  <FaCheckCircle className='text-success' />
                ) : (
                  <>
                    {loading ? (
                      <FaSpinner className='fa-spin' />
                    ) : (
                      <FaTimes className='text-danger opacity-5' />
                    )}
                  </>
                )}
              </span>
            </div>
          )}

          {(!fileEmbedded || canReEmbedResources(role)) && !loading && type && vectorSearchDocTypes.includes(type) && (
            <div className='border-top bg-light px-2 py-1 d-flex justify-content-end'>
              <button
                type="button"
                title='Embed Resource'
                className="small btn btn-sm btn-outline-primary d-flex align-items-center"
                onClick={handleCreateEmbedding}
                disabled={(fileEmbedded && !canReEmbedResources(role)) || loading || embedding}
              >
                <FaMagic size={10} />
                <span className='ml-1 small'>
                  {embedding ? 'Embedding...' : `${fileEmbedded ? 'Re-embed' : 'Embed'} Resource`}
                </span>
              </button>
            </div>
          )}

          {size && (
            <div className='border-top bg-light px-2 py-1 d-flex justify-content-end'>
              <small>
                Size: <span className='text-nowrap'>{prettyBytes(size)}</span>
              </small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditCell;
